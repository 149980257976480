import React, { useEffect, ReactNode } from "react";
import { Intercom } from "react-live-chat-loader";
import { getIPDetails } from "services";
import { WEBSITE_BASE_URL_CHINA, WEBSITE_BASE_URL_JAPAN, WEBSITE_BASE_URL_KOREA } from "variables";
import { lazily } from "react-lazily";
import { PublicNoticeButton } from "./common/public-notice";
const { AnnouncementBar } = lazily(() => import("./common/announcement-bar"));
const { SafetyButton } = lazily(() => import("./common/safety-button"));
const { Header } = lazily(() => import("./common/header/header"));
const { Footer } = lazily(() => import("components/library"));

type P = {
  children: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  showAnnouncementBar?: boolean;
  location?: any;
};

export default function Layout({
  children,
  showAnnouncementBar = true,
  showFooter = true,
  showHeader = true,
  location,
}: P) {
  const isSSR = typeof window === "undefined";

  async function handleCountryRedirect() {
    const currentUrl = window.location.href;

    if (!location.search.includes("manualRedirect=true")) {
      const dataFromApi = await getIPDetails();
      if (dataFromApi.error) return;
      if (
        dataFromApi.country === "JP" &&
        !currentUrl.includes(WEBSITE_BASE_URL_JAPAN)
      ) {
        window.location.href = WEBSITE_BASE_URL_JAPAN;
      }
      if (
        dataFromApi.country === "CN" &&
        !currentUrl.includes(WEBSITE_BASE_URL_CHINA)
      ) {
        window.location.href = WEBSITE_BASE_URL_CHINA;
      }
      if (
        dataFromApi.country === "KR" &&
        !currentUrl.includes(WEBSITE_BASE_URL_KOREA)
      ) {
        window.location.href = WEBSITE_BASE_URL_KOREA;
      }
    }
  }

  useEffect(() => {
    handleCountryRedirect();
  }, []);

  if (isSSR) return null;

  return (
    <div className="flex flex-col min-h-full">

      {/* English GTM */}

      {process.env.GATSBY_LANGUAGE_SELECTED !== "ja" && (
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KJCV59W"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      )}

      {/* Japanese GTM */}

      {process.env.GATSBY_LANGUAGE_SELECTED === "ja" && (
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NPHXXFGS"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      )}

      {/* Chinese GTM */}

      {process.env.GATSBY_LANGUAGE_SELECTED === "zh" && (
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NPHXXFGS"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      )}

      {showAnnouncementBar && <AnnouncementBar />}
      {/* <ConnectEarlyBirdPopup /> */}
      <SafetyButton />
      {process.env.GATSBY_LANGUAGE_SELECTED === "ja" && <PublicNoticeButton />}
      {showHeader && <Header />}
      {/* <ProductAlertModal /> */}
      <div className="grow">{children}</div>
      {showFooter && <Footer />}
      <Intercom />
    </div>
  );
}
