// import 'react-notion-x/src/styles.css'
import './src/styles/global.css';
import React from "react";
import Layout from "./src/components/layout";
import Seo from "./src/components/seo";
import { LiveChatLoaderProvider } from 'react-live-chat-loader'
import LivecycleSdk from "@livecycle/sdk";
LivecycleSdk.init();

export function wrapRootElement({ element }) {
    return (
        <LiveChatLoaderProvider providerKey="xumvd7ya" provider="intercom" locale={process.env.GATSBY_LANGUAGE_SELECTED}>
            {element}
        </LiveChatLoaderProvider>
    )
}

const profileCardPaths = {
    "/profile/kunal": true,
    "/profile/kunal/": true,
    "/profile/abhijit": true,
    "/profile/abhijit/": true,
    "/profile/kunal-gupta": true,
    "/profile/kunal-gupta/": true,
    "/profile/debarghya-saha": true,
    "/profile/debarghya-saha/": true,
}

const Wrapper = ({ children, ...props }) => {
    const isQRCodePage = profileCardPaths[props.location.pathname] ?? false

    return (
      <Seo {...props}>
        <Layout
          showFooter={!isQRCodePage}
          showHeader={!isQRCodePage}
          showAnnouncementBar={false}
          {...props}
        >
          {children}
        </Layout>
      </Seo>
    );
}

export function wrapPageElement({ element, props }) {
    const newElement = React.cloneElement(
        element,  // I18nextProvider
        element.props,
        React.cloneElement(
            element.props.children,  // I18nextContext.Provider
            element.props.children.props,
            React.createElement(
                Wrapper,
                props,
                element.props.children.props.children,
            ),
        ),
    );

    return newElement;
}

export function shouldUpdateScroll() {  
  window.scrollTo(0, 0)
  return false
}
