exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-applications-tsx": () => import("./../../../src/pages/applications.tsx" /* webpackChunkName: "component---src-pages-applications-tsx" */),
  "component---src-pages-connect-index-tsx": () => import("./../../../src/pages/connect/index.tsx" /* webpackChunkName: "component---src-pages-connect-index-tsx" */),
  "component---src-pages-connect-thank-you-tsx": () => import("./../../../src/pages/connect/thank-you.tsx" /* webpackChunkName: "component---src-pages-connect-thank-you-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-download-document-verify-tsx": () => import("./../../../src/pages/download-document/verify.tsx" /* webpackChunkName: "component---src-pages-download-document-verify-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-index-tsx": () => import("./../../../src/pages/info/index.tsx" /* webpackChunkName: "component---src-pages-info-index-tsx" */),
  "component---src-pages-ja-tsx": () => import("./../../../src/pages/ja.tsx" /* webpackChunkName: "component---src-pages-ja-tsx" */),
  "component---src-pages-japan-tsx": () => import("./../../../src/pages/japan.tsx" /* webpackChunkName: "component---src-pages-japan-tsx" */),
  "component---src-pages-japanese-tsx": () => import("./../../../src/pages/japanese.tsx" /* webpackChunkName: "component---src-pages-japanese-tsx" */),
  "component---src-pages-jp-tsx": () => import("./../../../src/pages/jp.tsx" /* webpackChunkName: "component---src-pages-jp-tsx" */),
  "component---src-pages-polymerize-labs-tsx": () => import("./../../../src/pages/polymerize-labs.tsx" /* webpackChunkName: "component---src-pages-polymerize-labs-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-products-ai-tsx": () => import("./../../../src/pages/products/ai.tsx" /* webpackChunkName: "component---src-pages-products-ai-tsx" */),
  "component---src-pages-products-data-management-tsx": () => import("./../../../src/pages/products/data-management.tsx" /* webpackChunkName: "component---src-pages-products-data-management-tsx" */),
  "component---src-pages-products-doe-tsx": () => import("./../../../src/pages/products/doe.tsx" /* webpackChunkName: "component---src-pages-products-doe-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-material-informatics-tsx": () => import("./../../../src/pages/products/material-informatics.tsx" /* webpackChunkName: "component---src-pages-products-material-informatics-tsx" */),
  "component---src-pages-products-overview-tsx": () => import("./../../../src/pages/products/overview.tsx" /* webpackChunkName: "component---src-pages-products-overview-tsx" */),
  "component---src-pages-profile-abhijit-tsx": () => import("./../../../src/pages/profile/abhijit.tsx" /* webpackChunkName: "component---src-pages-profile-abhijit-tsx" */),
  "component---src-pages-profile-anri-uozumi-tsx": () => import("./../../../src/pages/profile/anri-uozumi.tsx" /* webpackChunkName: "component---src-pages-profile-anri-uozumi-tsx" */),
  "component---src-pages-profile-debarghya-saha-tsx": () => import("./../../../src/pages/profile/debarghya-saha.tsx" /* webpackChunkName: "component---src-pages-profile-debarghya-saha-tsx" */),
  "component---src-pages-profile-kunal-gupta-tsx": () => import("./../../../src/pages/profile/kunal-gupta.tsx" /* webpackChunkName: "component---src-pages-profile-kunal-gupta-tsx" */),
  "component---src-pages-profile-kunal-tsx": () => import("./../../../src/pages/profile/kunal.tsx" /* webpackChunkName: "component---src-pages-profile-kunal-tsx" */),
  "component---src-pages-profile-rei-kurohi-tsx": () => import("./../../../src/pages/profile/rei-kurohi.tsx" /* webpackChunkName: "component---src-pages-profile-rei-kurohi-tsx" */),
  "component---src-pages-profile-yoichi-kadota-tsx": () => import("./../../../src/pages/profile/yoichi-kadota.tsx" /* webpackChunkName: "component---src-pages-profile-yoichi-kadota-tsx" */),
  "component---src-pages-public-notice-tsx": () => import("./../../../src/pages/public-notice.tsx" /* webpackChunkName: "component---src-pages-public-notice-tsx" */),
  "component---src-pages-register-now-tsx": () => import("./../../../src/pages/register-now.tsx" /* webpackChunkName: "component---src-pages-register-now-tsx" */),
  "component---src-pages-request-demo-index-tsx": () => import("./../../../src/pages/request-demo/index.tsx" /* webpackChunkName: "component---src-pages-request-demo-index-tsx" */),
  "component---src-pages-security-report-tsx": () => import("./../../../src/pages/security-report.tsx" /* webpackChunkName: "component---src-pages-security-report-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-pages-solutions-overview-tsx": () => import("./../../../src/pages/solutions/overview.tsx" /* webpackChunkName: "component---src-pages-solutions-overview-tsx" */),
  "component---src-pages-solutions-page-solutions-content-tsx": () => import("./../../../src/pages/solutions-page/solutionsContent.tsx" /* webpackChunkName: "component---src-pages-solutions-page-solutions-content-tsx" */),
  "component---src-pages-solutions-page-solutions-navbar-mobile-tsx": () => import("./../../../src/pages/solutions-page/solutionsNavbarMobile.tsx" /* webpackChunkName: "component---src-pages-solutions-page-solutions-navbar-mobile-tsx" */),
  "component---src-pages-solutions-page-solutions-navbar-tsx": () => import("./../../../src/pages/solutions-page/solutionsNavbar.tsx" /* webpackChunkName: "component---src-pages-solutions-page-solutions-navbar-tsx" */),
  "component---src-pages-solutions-polymerize-for-startups-tsx": () => import("./../../../src/pages/solutions/polymerize-for-startups.tsx" /* webpackChunkName: "component---src-pages-solutions-polymerize-for-startups-tsx" */),
  "component---src-pages-solutions-research-and-development-tsx": () => import("./../../../src/pages/solutions/research-and-development.tsx" /* webpackChunkName: "component---src-pages-solutions-research-and-development-tsx" */),
  "component---src-pages-solutions-researchers-tsx": () => import("./../../../src/pages/solutions/researchers.tsx" /* webpackChunkName: "component---src-pages-solutions-researchers-tsx" */),
  "component---src-pages-solutions-technology-scouts-tsx": () => import("./../../../src/pages/solutions/technology-scouts.tsx" /* webpackChunkName: "component---src-pages-solutions-technology-scouts-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-webinar-tsx": () => import("./../../../src/pages/webinar.tsx" /* webpackChunkName: "component---src-pages-webinar-tsx" */),
  "component---src-templates-career-position-tsx": () => import("./../../../src/templates/career-position.tsx" /* webpackChunkName: "component---src-templates-career-position-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-case-studies-n-white-papers-tsx": () => import("./../../../src/templates/case-studies-n-white-papers.tsx" /* webpackChunkName: "component---src-templates-case-studies-n-white-papers-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-events-page-tsx": () => import("./../../../src/templates/events-page.tsx" /* webpackChunkName: "component---src-templates-events-page-tsx" */),
  "component---src-templates-events-tsx": () => import("./../../../src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-newsletter-page-tsx": () => import("./../../../src/templates/newsletter-page.tsx" /* webpackChunkName: "component---src-templates-newsletter-page-tsx" */),
  "component---src-templates-newsletter-tsx": () => import("./../../../src/templates/newsletter.tsx" /* webpackChunkName: "component---src-templates-newsletter-tsx" */),
  "component---src-templates-newsroom-tsx": () => import("./../../../src/templates/newsroom.tsx" /* webpackChunkName: "component---src-templates-newsroom-tsx" */),
  "component---src-templates-white-paper-tsx": () => import("./../../../src/templates/white-paper.tsx" /* webpackChunkName: "component---src-templates-white-paper-tsx" */)
}

